import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';

const PhoneNumberFieldWithValidation = props => {

  const propsWithValidation = {
    ...props,
    validation: {
      ...props?.validation,
      regex: {
        pattern: /^[^a-zA-Z]*$/ig,
        message: 'You cannot enter letters in this field.',
      }
    }
  };

  return (
    <Field
      type="tel"
      as={Input}
      {...propsWithValidation}
    />
  );
};

PhoneNumberFieldWithValidation.propTypes = fieldPropTypes;

export default PhoneNumberFieldWithValidation;
